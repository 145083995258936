import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import "../sass/global/styles.scss";

const NotFoundPage = (props) => {
  const { data, location } = props;
  const { wordpressWpSettings, siteSettings } = data;
  const { title, wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  return (
    <section className="four-oh-four--component">
      <SEO title={`404 | ${title}`} location={location} />
      <Header location={location} theme="white" />
      <div className="four-oh-four--message">
        <div className="message-wrapper">
          <h2>Oops</h2>
          <h2>The page “/404.HTML”</h2>
          <h2>Cannot be found!</h2>
        </div>
      </div>
      <Footer location={location} />
    </section>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      title
      wordpressUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
  }
`;
